import React, {useEffect} from 'react';
import "./Contact.scss";

declare global {
    interface Window {
        hbspt: any;
    }
}

function Contact() {
    useEffect(() => {
        (function loadHubSpot() {
            window.hbspt.forms.create({ 
                region: "na1",
                portalId: "5152848",
                formId: "31b2c735-781a-432f-83b4-3fb7521ac1a4",
                target: '.contact-wrapper'
            });
        })();
    }, []);

    return (
        <><div className="contacts-block">
            <p className={'contact-us-form'}>Contact us:</p>
            <div className="contact-wrapper animate__animated animate__zoomInDown"></div>
            </div>
        </>
    );
}

export default Contact;
